import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import Socials from "components/socials";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import Image from "components/image";
import TOC from "components/toc";
import BasicTable from "components/basicTable";
import List from "components/list";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/celeron"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/celeron.png"}) { ...eyecatchImg },
    cssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    cusedshop: file(relativePath: { eq: "cover/rank/usedshop.png"}) { ...eyecatchImg },
    allCeleronJson {
      nodes {
        name
        pm
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Celeron比較表 セレロンはゴミではなく優秀なセカンドパソコン`}</h1>
    <time itemProp="datePublished" dateTime="Fri Feb 07 2025 23:37:36 GMT+0900 (日本標準時)">更新日:2025年2月7日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Celeron比較表" mdxType="Image" />
    <p>{`CeleronはIntelのローエンド向けCPUのブランドであり、格安パソコンに多く搭載されている。性能は確かに低いが、用途を限定して、セカンドパソコンとして使うのであれば十分な性能を持っている。`}</p>
    <p>{`しかしネットの評判を見ていると`}<strong parentName="p"><em parentName="strong">{`Celeronは使えない、やめとけとのコメントも多く、失敗する人は大勢`}</em></strong>{`いる。`}</p>
    <p>{`また、CeleronもCore iシリーズなど他のCPUと同様毎年新製品が複数発表され、性能が順次上がっていくため、`}<strong parentName="p"><em parentName="strong">{`同じCeleronでも型番が異なると快適さが大きく変わってくる`}</em></strong>{`。`}</p>
    <p>{`そこで本ページでは`}<strong parentName="p"><em parentName="strong">{`Celeronの特徴やどのような使い方をする人におすすめできるのかを説明する他、複数種類のCeleron CPUをまとめて比べられるよう比較表を用意`}</em></strong>{`した。Celeronと同程度の性能を誇るCore iなどのCPUの情報も含めて提供する。`}</p>
    <p>{`より多くのCPUを調べたい人は`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`を参考にして欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Celeron搭載パソコンは使えないゴミなのか？特徴を列挙",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Celeron%E6%90%AD%E8%BC%89%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AF%E4%BD%BF%E3%81%88%E3%81%AA%E3%81%84%E3%82%B4%E3%83%9F%E3%81%AA%E3%81%AE%E3%81%8B%EF%BC%9F%E7%89%B9%E5%BE%B4%E3%82%92%E5%88%97%E6%8C%99",
        "aria-label": "Celeron搭載パソコンは使えないゴミなのか？特徴を列挙 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Celeron搭載パソコンは使えないゴミなのか？特徴を列挙`}</h2>
    <p>{`Celeron搭載パソコンをカスだのゴミだの、使えないと言うのは、事前に十分にCeleronの能力について認識をしていなかったため起こったミスマッチによるものである。`}</p>
    <p>{`使用頻度や目的を事前に想定しておけば防げることなので、Celeronパソコンを使うと体感的にどのような印象を受けるのかを説明する。`}</p>
    <h3 {...{
      "id": "全体的にもっさり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%85%A8%E4%BD%93%E7%9A%84%E3%81%AB%E3%82%82%E3%81%A3%E3%81%95%E3%82%8A",
        "aria-label": "全体的にもっさり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`全体的にもっさり`}</h3>
    <p>{`低速なCPUの特徴であるが、`}<strong parentName="p"><em parentName="strong">{`アプリの起動や、ブラウザでタブを開く時の動作が遅いなど、基本的な動作がもっさり`}</em></strong>{`と感じる。別に不都合は感じないのだけど絶妙な感じで遅い。`}</p>
    <p>{`インターネットとメールだけならCeleronで十分であると店舗などに出向くと説明されるが、このようなベーシックな動作まで遅いので、ナンセンスな発言である。`}</p>
    <p>{`ただし、重いファイルを開かない、いくつもタブを開かない、バックグラウンドで処理を走らせないなど注意して使うと大して速度が変わらなかったりもする。`}</p>
    <p>{`このため店舗で新品のCeleronパソコンを触った時は速い感じたのだが、使い始めているうちに色々なソフトを裏で動かすようになり遅くなったと後悔する人は多い。`}</p>
    <h3 {...{
      "id": "3Dゲームは無謀",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3D%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AF%E7%84%A1%E8%AC%80",
        "aria-label": "3Dゲームは無謀 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3Dゲームは無謀`}</h3>
    <p>{`高度な3Dゲームには全く対応ができない、また簡易な3Dゲームであってももっさりするため、麻雀やマインスイーパーをするなら全く問題は無いが、ファイナルファンタジーなどの3Dゲームは最低画質でも絶望的である。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`3Dのゲームはやらないと割り切る`}</em></strong>{`ことが重要である。`}</p>
    <h3 {...{
      "id": "動画編集は快適ではないが動かせる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%81%AF%E5%BF%AB%E9%81%A9%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84%E3%81%8C%E5%8B%95%E3%81%8B%E3%81%9B%E3%82%8B",
        "aria-label": "動画編集は快適ではないが動かせる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画編集は快適ではないが動かせる`}</h3>
    <p>{`遅いと言えば遅いが、`}<strong parentName="p"><em parentName="strong">{`HD画質の動画にテロップの載せる程度の動作は何なくこなす事ができる`}</em></strong>{`。`}</p>
    <p>{`このため頻繁に動画編集をしているような人にはもちろん向かないが、結婚式用のムービーを作成するなど、`}<strong parentName="p"><em parentName="strong">{`イベントごとのためにたまに使う程度なら問題はない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "Officeはそこそこ動く",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Office%E3%81%AF%E3%81%9D%E3%81%93%E3%81%9D%E3%81%93%E5%8B%95%E3%81%8F",
        "aria-label": "Officeはそこそこ動く permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Officeはそこそこ動く`}</h3>
    <p>{`WordやExcel、PowerPointなどのソフトは、ただ文字を打ったり、家計簿のために表計算をしたり、絵をペタペタ張ってスライドを作るだけなのでCeleronでも十分にできる。`}</p>
    <p>{`もちろん起動動作などはもっさりするが、それよりも考えている時間の方が長かったりするのでまあCeleronでも十分なのではないかと思う。`}</p>
    <h3 {...{
      "id": "マルチタスクはつらい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9E%E3%83%AB%E3%83%81%E3%82%BF%E3%82%B9%E3%82%AF%E3%81%AF%E3%81%A4%E3%82%89%E3%81%84",
        "aria-label": "マルチタスクはつらい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マルチタスクはつらい`}</h3>
    <p>{`たとえばZoomやスカイプで友達と楽しく話す、という用途には全く問題はないが、Excelの資料を全体に共有しながらオンライン会議で話すといったマルチタスクとなるとCPUを大幅に使ってしまい厳しい時もある。`}</p>
    <p>{`背景をぼかすなどの処理ともあまり相性は良くない。基本的に`}<strong parentName="p"><em parentName="strong">{`マルチタスクにCeleronは弱いので、同時に起動させるソフトが最低限になるように気を使いながら操作するのが正しいCeleronパソコンの使い方`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "Celeronの特徴まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Celeron%E3%81%AE%E7%89%B9%E5%BE%B4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "Celeronの特徴まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Celeronの特徴まとめ`}</h3>
    <p>{`全体的にもっさりでできない事もあるが、`}<strong parentName="p"><em parentName="strong">{`パソコン作業で必要な大抵のことはできるので値段相応の価値は十分にある`}</em></strong>{`かと思われる。`}</p>
    <List mdxType="List">
      <li>Webサイト閲覧、4K動画の視聴などは余裕だが動作は絶妙なもっさり感</li>
      <li>3Dゲームはできない</li>
      <li>動画編集はFullHDまでで簡単なものなら遅いが可能。4K動画編集は無謀</li>
      <li>Officeは問題なく動く</li>
      <li>マルチタスクはもっさり感が増す</li>
    </List>
    <h2 {...{
      "id": "Core-iの性能と並ぶこともある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i%E3%81%AE%E6%80%A7%E8%83%BD%E3%81%A8%E4%B8%A6%E3%81%B6%E3%81%93%E3%81%A8%E3%82%82%E3%81%82%E3%82%8B",
        "aria-label": "Core iの性能と並ぶこともある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core iの性能と並ぶこともある`}</h2>
    <p>{`IntelのCore iシリーズには低消費電力向けのものからデスクトップ向けのハイパフォーマンスなものまで数多くの種類があるが、特にCore iの型番の最後にYが付くYシリーズは性能が低く、電力効率は高いものCeleron並みのパフォーマンスしか出ない。`}</p>
    <p>{`あるいは数年前に販売された数世代前のCore iシリーズのCPUと最新世代のCeleronを比べると最新世代のCeleronの方が性能が高い場合も多い。`}</p>
    <p>{`下手に`}<strong parentName="p"><em parentName="strong">{`高い金を出してCore iシリーズを選ぶよりは性能が低いと割り切ったうえでCeleronを選んだ方が正解なケースもある`}</em></strong>{`わけである。`}</p>
    <h2 {...{
      "id": "Celeronをおすすめしたいケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Celeron%E3%82%92%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%97%E3%81%9F%E3%81%84%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "Celeronをおすすめしたいケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Celeronをおすすめしたいケース`}</h2>
    <p>{`Celeronでできる事、できない事、パフォーマンスについて理解できたと思うので、この情報をもとにCeleronをおすすめしたいケースを洗い出していく。`}</p>
    <h3 {...{
      "id": "軽い用途に限定される",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%BB%BD%E3%81%84%E7%94%A8%E9%80%94%E3%81%AB%E9%99%90%E5%AE%9A%E3%81%95%E3%82%8C%E3%82%8B",
        "aria-label": "軽い用途に限定される permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`軽い用途に限定される`}</h3>
    <p>{`インターネットでの買い物、SNS、メール、Youtubeなどを手軽に楽しみたいだけならばCeleronで十分に用途を果たすことができるであろう。Officeについても問題はない。`}</p>
    <p>{`動画編集も4K動画の編集など重い処理をしなければそこそこ動いてくれるはずである。`}</p>
    <p>{`全体的に動きはもっさりしているが、あまり同時に複数のアプリやタブを開かない限りそれなりに動く。`}</p>
    <h3 {...{
      "id": "使用頻度が低い、セカンドPCとして",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BD%BF%E7%94%A8%E9%A0%BB%E5%BA%A6%E3%81%8C%E4%BD%8E%E3%81%84%E3%80%81%E3%82%BB%E3%82%AB%E3%83%B3%E3%83%89PC%E3%81%A8%E3%81%97%E3%81%A6",
        "aria-label": "使用頻度が低い、セカンドPCとして permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`使用頻度が低い、セカンドPCとして`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`パソコンの使用頻度はCeleron CPUを搭載するかの大きな決定要素`}</em></strong>{`となる。`}</p>
    <p>{`１時間ネットサーフィンを行うとして、Core i5のCPUとCeleronでは合計１分間程度ローディングの待ち時間で差がでるとする。大体一般的な使用ケースを想定するとこれぐらいだろう。`}</p>
    <p>{`週に１回使うとすると、１年間で約50分、５年間程度パソコンは使うので約４時間の待ち時間が発生する。一方毎日１時間だとすると５年間で２８時間待ち時間を過ごしていることになる。`}</p>
    <p>{`Core i5を使用した時に生じる待ち時間の短縮幅を時給に換算して考えると次のような図になる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th>使用時間</th>
      <th>週に１時間</th>
      <th>毎日１時間</th>
      <th>毎日３時間</th>
      <th>毎日８時間</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>５年の時短効果</td>
      <td>４時間</td>
      <td>２８時間</td>
      <td>８４時間</td>
      <td>２２４時間</td>
    </tr>
    <tr>
      <td>時給1000円とすると</td>
      <td>4000円</td>
      <td>2万8000円</td>
      <td>8万4000円</td>
      <td>22万4000円</td>
    </tr>
    <tr>
      <td>時給2000円とすると</td>
      <td>8000円</td>
      <td>5万6000円</td>
      <td>16万8000円</td>
      <td>44万8000円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`このように考えると`}<strong parentName="p"><em parentName="strong">{`定常的にパソコンを使わない人はCeleronで全く構わない`}</em></strong>{`ということになる。一方で、仕事でパソコンを使うというような人は`}<strong parentName="p">{`生産性を最大限上げるために`}<em parentName="strong">{`全力で高いものを購入した方が実は割安`}</em></strong>{`という事が言える。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`そもそものパソコンの使用頻度が低い人や、手軽に持ち歩けるセカンドPCとして使いたい人にとってCeleronは非常におすすめ`}</em></strong>{`ができる。`}</p>
    <p>{`逆に一般的なサラリーマンで毎日１時間以上パソコンを使う人は、５万円高く払いCore i5のパソコンを購入した方が経済合理性の上では有利ということができる。`}</p>
    <h3 {...{
      "id": "節約したい、経済的な面から",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%AF%80%E7%B4%84%E3%81%97%E3%81%9F%E3%81%84%E3%80%81%E7%B5%8C%E6%B8%88%E7%9A%84%E3%81%AA%E9%9D%A2%E3%81%8B%E3%82%89",
        "aria-label": "節約したい、経済的な面から permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`節約したい、経済的な面から`}</h3>
    <p>{`前節では経済合理性の観点でCeleronを購入すべきか説明したが、残念ながら単純にお金がなく、予算の絶対額が決まっているというケースも多いだろう。`}</p>
    <p>{`そのようなCore iシリーズ搭載のパソコンを購入したいがCeleronを購入せざるを得ないというケースでは本ページ最後の`}<strong parentName="p"><em parentName="strong">{`Celeron比較表をきっちりと確認し、できる限り性能の良いものを選びたい`}</em></strong>{`。`}</p>
    <p>{`また、こまめにアプリケーションを閉じる、バックグラウンドで処理を走らせないなど工夫を凝らせばそれなりに高速性を保つことができるので、パソコンに詳しくない人はある程度軽くするための知識を付けていくことをおすすめする。`}</p>
    <h2 {...{
      "id": "Celeronやめとけ派の主張",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Celeron%E3%82%84%E3%82%81%E3%81%A8%E3%81%91%E6%B4%BE%E3%81%AE%E4%B8%BB%E5%BC%B5",
        "aria-label": "Celeronやめとけ派の主張 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Celeronやめとけ派の主張`}</h2>
    <p>{`これはパソコンに詳しく無い人が、Celeronと知らずに価格の安さに釣られて、あるいは大手国内メーカーの10万円以上するパソコンを購入し、それをメインPCとして使っている人が声高に唱える主張である。`}</p>
    <p>{`メインPCとしては前述の通り、もっさりしていることと、重いアプリケーションを動かそうとしたら度々強制終了に悩まされる危険もあるので絶対にやめておいた方が良いと言える。`}</p>
    <p>{`Celeronにも種類があるが、最も高性能なCeleronでもやはり後述するベンチマークスコアは低いので、`}<strong parentName="p"><em parentName="strong">{`メインPCとして如何なるCeleronであれ避けるべき`}</em></strong>{`と覚えておくと良い。`}</p>
    <h2 {...{
      "id": "５万円のCeleron搭載パソコンでのおすすめ構成",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%BC%95%E4%B8%87%E5%86%86%E3%81%AECeleron%E6%90%AD%E8%BC%89%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A7%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E6%A7%8B%E6%88%90",
        "aria-label": "５万円のCeleron搭載パソコンでのおすすめ構成 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`５万円のCeleron搭載パソコンでのおすすめ構成`}</h2>
    <p>{`２万円ぐらいでもノートパソコンが売っているが、あまりにポンコツな作りをしていると安物買いの銭失いになるだけである。`}<strong parentName="p"><em parentName="strong">{`質感が安っぽすぎたり、音が常にギシギシ鳴っていたりと経験上ロクなことはない`}</em></strong>{`。`}</p>
    <p>{`５万円程度を狙って購入すると、筐体もそれなりにしっかりし、動作としても安定感のあるものとなりおすすめである。ここでは５万円以内に収まる範囲でのおすすめのスペックを紹介する。`}</p>
    <h3 {...{
      "id": "HDDではなくSSD搭載",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HDD%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%8FSSD%E6%90%AD%E8%BC%89",
        "aria-label": "HDDではなくSSD搭載 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDではなくSSD搭載`}</h3>
    <p>{`アプリケーションの起動時の速度はおおよそ、アプリケーションファイルの読み込み時間 + 初期化処理で決まる。`}</p>
    <p>{`初期化処理は前にアプリを使っていた状態を復元する、ネットワーク通信によりアプリのアップデートが無いかを確認するなどCPUの果たす役割が大きい。`}</p>
    <p>{`しかしアプリケーションファイルの読み込み時間はストレージのファイル読み込みが高速かどうかで決まり、特に`}<strong parentName="p"><em parentName="strong">{`OSの起動はHDD(ハードディスク)と比べてSSDは読み込み時間が半分になり快適さが大きく向上`}</em></strong>{`する。`}</p>
    <p>{`Core i7でHDDのパソコンよりも、Celeron + SSDのパソコンの方がOSの起動は速い。`}</p>
    <h3 {...{
      "id": "メモリは4GBでも良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF4GB%E3%81%A7%E3%82%82%E8%89%AF%E3%81%84",
        "aria-label": "メモリは4GBでも良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは4GBでも良い`}</h3>
    <p>{`メモリはアプリケーションを動かすために必要な領域であり、値が大きいほど複数のアプリを快適に動かすことができる。`}</p>
    <p>{`しかしCeleronは馬力に欠け、複数のソフトを同時に動かそうとするとCPUのリソース不足で遅くなるためメモリが十分にあっても約に立たない場合が多い。`}</p>
    <p>{`なのでCeleron搭載のパソコンで8GBを搭載する必要性は薄い。動画編集をそれなりにやろうと考えているならば8GBあっても良いが、その場合はCore iシリーズをおすすめしたい。`}</p>
    <h3 {...{
      "id": "SSDは128GB以上は欲しい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AF128GB%E4%BB%A5%E4%B8%8A%E3%81%AF%E6%AC%B2%E3%81%97%E3%81%84",
        "aria-label": "SSDは128GB以上は欲しい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDは128GB以上は欲しい`}</h3>
    <p>{`64GBの製品も売っているが、WindowsのOS自体が容量を逼迫し、実際に使用可能な領域は40GB程度になる。これにWindows Updateなどが重なるとほとんどパソコンにアプリをインストールしていないつもりでも容量まで行ってしまう。`}</p>
    <p>{`Windowsパソコンを使う最低が128GBと認識しておくと良いだろう。詳しくは下記リンクも参考にして欲しい。`}</p>
    <Card {...props} title="SSDの容量のおすすめは何か？" description="128GBでもきついが64GBはちょっと現実的でないという事が分かります。" image="cssd_size" path="/ssd/" mdxType="Card" />
    <h3 {...{
      "id": "Chromebookも買い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Chromebook%E3%82%82%E8%B2%B7%E3%81%84",
        "aria-label": "Chromebookも買い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Chromebookも買い`}</h3>
    <p>{`インターネットを楽しむだけという場合にはChrome BookというWindowsではなくGoogleのChrome OSを採用したパソコンを購入しても良いだろう。`}</p>
    <p>{`起動がWindowsと比べて速く、快適にネットショッピングや動画を楽しむことができる。またファイルはクラウドでの保存が基本となり、Windowsほど容量は必要はないため64GBでも問題はない。`}</p>
    <p>{`ただし、使い勝手がWindowsと異なり、特に割安ということはなく、パソコンに困った時の情報量もWindowsと比べると少ないため、初心者は手を出さない方が無難である。`}</p>
    <h2 {...{
      "id": "中古パソコン-vs-Celeron新品",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3-vs-Celeron%E6%96%B0%E5%93%81",
        "aria-label": "中古パソコン vs Celeron新品 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコン vs Celeron新品`}</h2>
    <p>{`中古パソコンならば高性能なCore iシリーズを積んだパソコンが割安で手に入るので、同価格ならばCeleronパソコンの新品よりも中古の方が良いという人もいる。`}</p>
    <p>{`本当にそうでしょうか？`}<strong parentName="p">{`バッテリーの寿命が思ったよりも持たない、あるいは電池が膨らんできている、キーボードの利きが悪い、ハードディスクやSSDの寿命が残り少ないなどのリスクを考えると、大抵の中古品は割高`}</strong>{`である、という意見もある。`}</p>
    <p>{`しかし、`}<strong parentName="p"><em parentName="strong">{`法人向けパソコンを再整備して売りに出しているパソコンはクオリティが高く`}</em></strong>{`、ショップによっては電池残量を表示してくれていたりする。あるいはデスクトップパソコンならば故障のリスクは非常に低い。`}</p>
    <p>{`下記におすすめショップのリンクを貼るのでCeleronを購入しようか迷っている人は是非参考にしたい。`}</p>
    <Card {...props} title="中古パソコンおすすめショップランキング2023" description="中古パソコンのおすすめショップをランキング形式で紹介。品質とコスパを両立させた優良ショップを厳選します。" image="cusedshop" path="/used-shop/" mdxType="Card" />
    <h2 {...{
      "id": "Celeron-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Celeron-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Celeron 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Celeron 性能比較表`}</h2>
    <p>{`最後に同レベルのCPUと一緒に数多くあるCeleronをベンチマークスコア(PassMark Score)が高い順に紹介する。`}</p>
    <p>{`同じCeleronであってもスコアで２倍以上の差が付くこともザラにあり、このCPUのランク差が値段にも反映されている。`}</p>
    <p>{`Celeron搭載パソコンを購入する時は少しでも快適になるように、CPUの性能に注意を向け、価格と性能とのバランスをしっかりと考えよう。`}</p>
    <TableDescription mdxType="TableDescription" />
    <p>{`PassMarkの点数の目安を主観ではあるが下記に示しておく。比較検討で役立つだろう。Celeronは当然の事ながら低い。ただ`}<strong parentName="p"><em parentName="strong">{`カスはカスでも全く使えないという訳ではない`}</em></strong>{`
。`}</p>
    <PassMark mdxType="PassMark" />
    <HikakuTable {...props} checkboxes={[]} mobileWidth={'100%'} cpuData={props.data.allCeleronJson.nodes} fieldArray={[{
      id: 'name',
      filterType: 'string'
    }, {
      id: 'pm'
    }]} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      